import Diff from "../utilities/Diff";
import "./HistoryPopup.css";

import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { getRevisionHistory } from "../utilities/firebaseUtils";

function SummaryPopup() {
  const [history, setHistory] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    const generateSummary = async (email) => {
      const history = await getRevisionHistory(email);
      history.sort((a, b) => a.style.localeCompare(b.style));
      setHistory(history);
    };
    generateSummary(auth.currentUser.email);
  }, []);

  useEffect(() => {
    let currentStyle = null;
    const rows = [];
    history.forEach((record, index) => {
      if (record.style !== currentStyle) {
        rows.push(
          <tr key={`${record.input}-${record.style}`}>
            <td rowSpan={history.filter((r) => r.style === record.style).length}>{record.style}</td>
            <td>{record.input}</td>
            <td>
              <Diff string1={record.input} string2={record.suggestion} />
            </td>
          </tr>
        );
        currentStyle = record.style;
      } else {
        rows.push(
          <tr key={`${record.input}-${record.style}`}>
            <td>{record.input}</td>
            <td>
              <Diff string1={record.input} string2={record.suggestion} />
            </td>
          </tr>
        );
      }
      setRows(rows);
    });
  }, [history]);

  return (
    <div className="history-table-container">
      <table class="history-table">
        <colgroup>
          <col className="col-style" />
          <col className="col-input" />
          <col className="col-suggestion" />
        </colgroup>
        <thead>
          <tr>
            <th>Style</th>
            <th>Input</th>
            <th>Suggestion</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

export default SummaryPopup;
