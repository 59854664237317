// Define the removeQuotes function
function processSuggestions(str) {
  let result = str;

  // Example:
  // Reword: "Hello world" => "Hello world"
  result = result.replace(
    /^(Reword|Reworded|Rewritten|Rephrased|Rephrase|Revise):\s+([^"]+)$/,
    "$2"
  );

  // Remove quotes from the beginning and end.
  //
  // Example:
  // "Hello world" => Hello world
  result = result.replace(/^['"](.*)['"]$/, "$1");

  if (result !== str) {
    // console.log("input: ", str);
    // console.log("result: ", result);
  }

  return result;
}

// Define a utility function to simplify the test cases
// function assertTest(input, expected) {
//   console.log("input: " + input);
//   console.log("expected: " + expected);
//   let result = processSuggestions(input);
//   console.assert(
//     result === expected,
//     `Expected "${expected}", but got "${result}"`
//   );
// }

// Define the test cases using the assertTest function
// function testRemoveQuotes() {
//   assertTest('"Hello, world!"', "Hello, world!");

//   assertTest('reword: "Hello, world!"', "Hello, world!");

//   assertTest("Hello, world!", "Hello, world!");
// }

// Call the test function to run the tests
// testRemoveQuotes();

export { processSuggestions };
