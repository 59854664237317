import { OPENAI_MAX_TOKEN, OPENAI_MODEL, OPENAI_TEMPERATURE, ZUPLO_API_URL } from "common/const";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useEffect, useRef, useState } from "react";
import { analyticsEvent, updateDataRecord } from "./firebaseUtils";
import { processSuggestions } from "./processSuggestionsUtils";
import { promptFormatter } from "./promptFormatter";
const useRephraseText = (prop) => {
  const streamDataRef = useRef("");
  const [rephraseStream, setRephraseStream] = useState("");
  const { headers, anonymous, email, rephraseInput, rephraseSuggestion, rephraseStyle, id, lang } = prop;

  useEffect(() => {
    if (streamDataRef.current !== "") return;
    const { system, user, langJudge } = promptFormatter(rephraseInput, rephraseStyle, rephraseSuggestion, lang);
    const evtSource = new EventSourcePolyfill(
      process.env.REACT_APP_ZUPLO_URL +
        ZUPLO_API_URL +
        `?` +
        `langJudge=${encodeURIComponent(langJudge)}` +
        `&model=${encodeURIComponent(OPENAI_MODEL)}` +
        `&system=${encodeURIComponent(system)}` +
        `&user=${encodeURIComponent(user)}` +
        `&maxTokens=${encodeURIComponent(OPENAI_MAX_TOKEN)}` +
        `&temperature=${encodeURIComponent(OPENAI_TEMPERATURE)}` +
        `&stream=true`,
      {
        headers: headers,
      }
    );
    const evtSourceProcess = () => {
      evtSource.onmessage = (event) => {
        if (event.data === "[DONE]") {
          evtSource.close();
          setRephraseStream((prev) => processSuggestions(prev));
          streamDataRef.current = processSuggestions(streamDataRef.current);
          if (id !== "") {
            updateDataRecord(email, rephraseInput, rephraseStyle, id, streamDataRef.current, anonymous);
            analyticsEvent({
              whichCategory: "rephraseItem",
              rephraseStyle: rephraseStyle,
              rephreasText: rephraseInput,
              rephraseOutput: streamDataRef.current,
            });
          } else {
            analyticsEvent({
              whichCategory: "rephraseItem",
              rephraseStyle: rephraseStyle,
              rephreasText: rephraseInput,
              revision: rephraseSuggestion,
            });
          }

          return;
        }

        const newContent = JSON.parse(event.data).choices[0].delta.content ?? "";

        streamDataRef.current += newContent;
        setRephraseStream((prev) => prev + newContent);
      };
    };
    evtSourceProcess();

    // eslint-disable-next-line
  }, []);

  return rephraseStream;
};

const OpenaiUtils = (prop) => {
  return <>{useRephraseText(prop)}</>;
};

export default OpenaiUtils;
