import { signInWithPopup, signOut } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { auth, googleProvider, notPremiumURL } from "../utilities/firebaseUtils";
import HistoryPopup from "./HistoryPopup";
import "./InformButton.css";
const InformButton = ({ setLogin, login, isLoadingForLoginButton }) => {
  const dropdownRef = useRef(null);
  const [accountClick, setAccountClick] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setAccountClick(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, [dropdownRef]);

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      setLogin(true);
    } catch (err) {
      console.error(err);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setLogin(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getCustomClaimRole = async () => {
    await auth.currentUser.getIdToken(true);
    const decodedToken = await auth.currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
  };

  const billingPortal = async () => {
    const newWindow = window.open("", "_blank");
    newWindow.document.write("<p>Loading...</p>");
    const customClaimRole = await getCustomClaimRole();
    // console.log(customClaimRole);
    if (customClaimRole === "premium") {
      const { data } = await httpsCallable(getFunctions(), "ext-firestore-stripe-payments-createPortalLink")({ returnUrl: window.location.origin });
      console.log(data.url);
      newWindow.location.assign(data.url);
    } else {
      notPremiumURL(newWindow);
    }
  };

  // const feedback = () => {
  //   window.open(process.env.REACT_APP_FEEDBACK_URL, "_blank");
  // };

  const handleClick = () => {
    setAccountClick((prev) => !prev);
  };

  return (
    <>
      {!isLoadingForLoginButton &&
        (login ? (
          <div className="dropdown" onClick={handleClick} ref={dropdownRef}>
            <button className="drop-button">Account</button>
            <div className={`dropdown-content ${accountClick ? "is-clicked" : ""}`}>
              <a onClick={billingPortal}>Subscription</a>
              <a onClick={openModal}>History</a>
              <a onClick={logout}>Logout</a>
            </div>
          </div>
        ) : (
          <button className="login-button" onClick={signInWithGoogle}>
            Login
          </button>
        ))}

      <Modal appElement={document.getElementById("root")} isOpen={isOpen} onRequestClose={closeModal}>
        <HistoryPopup />
      </Modal>
    </>
  );
};

export default InformButton;
