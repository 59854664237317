import React from "react";
import * as diff from "diff";
import PropTypes from "prop-types";
window.diff = diff;

const styles = {
  added: {
    color: "green",
    fontColor: "#e6ffe6",
  },
  removed: {
    color: "red",
    fontColor: "#ffe6e6",
  },
};

const Diff = ({ string1 = "", string2 = "", mode = "sentences" }) => {
  let groups = [];

  if (mode === "characters") groups = diff.diffChars(string1, string2);
  if (mode === "words") groups = diff.diffWords(string1, string2);
  if (mode === "sentences") groups = diff.diffSentences(string1, string2);

  // console.log(groups);
  const mappedNodes = groups.map((group) => {
    const { value, added, removed } = group;
    if (value.trim().length === 0) {
      return <br></br>;
    }
    let nodeStyles;
    if (added) nodeStyles = styles.added;
    if (removed) nodeStyles = styles.removed;
    return <li style={nodeStyles}>{value}</li>;
  });

  return <ul>{mappedNodes}</ul>;
};

Diff.propTypes = {
  string1: PropTypes.string,
  string2: PropTypes.string,
  mode: PropTypes.oneOf(["characters", "words", "sentences"]),
};

export default Diff;
