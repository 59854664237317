import "./SnackBar.css";

const SnackBar = ({ message, isVisible }) => {
  return (
    <div
      id="snackbar"
      style={{
        visibility: isVisible ? "visible" : "hidden",
      }}
    >
      {message}
    </div>
  );
};

export default SnackBar;
