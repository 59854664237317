import { useContext, useRef, useState } from "react";
import OpenaiUtils from "utilities/openaiUtils";
import LanguageContext from "../contexts/LangContext";
import { performRevisionAction } from "../utilities/firebaseUtils";
import "./OutputArea.css";
import RephraseItem from "./RephraseItem";
const OutputArea = ({
  textarea,
  headerRef,
  anonymousRef,
  isFlipped,
  emailRef,
  setIsFlipped,
  rephraseItem,
  setMessage,
  isVisibleRef,
  setIsVisible,
  revisionButtonClick,
  setRevisionButtonClick,
}) => {
  const idRef = useRef(null);
  const [revisionText, setRevisionText] = useState("");
  const { lang } = useContext(LanguageContext);
  const handleRevisonButtonClick = () => {
    setRevisionButtonClick(true);
    performRevisionAction(idRef.current, "markRevisionExplanationClicked");
  };

  return (
    <div data-testid="output-area" className="output-area">
      {rephraseItem.map((item) => {
        const { id, rephraseText } = item;
        return (
          <RephraseItem
            key={id}
            setMessage={setMessage}
            isVisibleRef={isVisibleRef}
            setIsVisible={setIsVisible}
            setRevisionText={setRevisionText}
            setIsFlipped={setIsFlipped}
            idRef={idRef}
            isFlipped={isFlipped}
            id={id}
            rephraseText={rephraseText}
          />
        );
      })}
      {isFlipped && (
        <>
          <button
            className="remove-btn"
            data-testid="output-remove-button"
            onClick={() => {
              setIsFlipped((prevIsFlipped) => !prevIsFlipped);
              setRevisionButtonClick(false);
            }}
          ></button>
          <div id="back">{revisionText}</div>
          <div id="revision">
            {!revisionButtonClick ? (
              <button className="revision-explanation-button " onClick={handleRevisonButtonClick}>
                How is this revision better ?
              </button>
            ) : (
              <div className="revision-explanation-container">
                <p className="revision-explaination-title">How is this revision better ?</p>
                <p className="revision-explanation-content">
                  {
                    <OpenaiUtils
                      headers={headerRef.current}
                      anonymous={anonymousRef.current}
                      email={emailRef.current}
                      rephraseInput={textarea}
                      rephraseSuggestion={revisionText}
                      rephraseStyle="revisionExplanation"
                      id=""
                      lang={lang}
                    />
                  }
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default OutputArea;
