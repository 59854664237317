export const promptFormatter = (rephraseInput, rephraseStyle, rephraseSuggestion, lang) => {
  let system = "";
  let user = "";
  let langJudge = `Responses must be provided in the following language abbreviation: ${lang} `;
  const words = rephraseInput.split(" ");

  if (words.length <= 2 && rephraseSuggestion === "") {
    return {
      system: "",
      user: `Rephrase these words: ${rephraseInput}.`,
    };
  }

  switch (rephraseStyle) {
    case "Rephrase":
      system = `Rephrase text to be more clear and concise: ${rephraseInput}.`;
      break;

    case "revisionExplanation":
      system = `List specific examples to examine how the revision is better than original text based on syntax, word choice, and grammar. Answer must not exceed 100 words. Responses must be provided in the following language abbreviation: ${lang}`;
      user = `Orginal: ${rephraseInput}.\n Revision: ${rephraseSuggestion}.`;
      break;

    case "Shorten":
      let shortenTargetLength = Math.floor(words.length * 0.5);
      system = `Rephrase text to be more clear, concise, and short: ${rephraseInput}. Return in ${shortenTargetLength} words.`;
      break;

    case "Expand":
      let expandTargetLength = words.length + 20;
      system = `Rephrase text to be more clear and expand: ${rephraseInput}. Return in ${expandTargetLength} words.`;
      break;

    case "Make Casual":
      system = `Rephrase text to be more clear, concise, and casual: ${rephraseInput}.`;
      break;

    case "Make Formal":
      system = `Rephrase text to be more clear, concise, and formal: ${rephraseInput}. Return in ${words.length} words.`;
      break;

    default:
      break;
  }
  return { system, user, langJudge };
};
