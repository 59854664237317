import ReactDOM from "react-dom/client";
import { LanguageProvider } from "./contexts/LangContext";
import Editor from "./editor/Editor";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <LanguageProvider>
      <Editor />
    </LanguageProvider>
  </>
);
