import styles from "./PromotionBanner.module.css";

const PromotionBanner = ({ times }) => {
  return (
    <div className={styles.banner}>
      {100 - times} revisions left! Get 80% off the first month of premium membership with
      coupon code: PHRASELY099
    </div>
  );
};

export default PromotionBanner;
