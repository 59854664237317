import "./InputArea.css";

import RephraseButton from "./RephraseButton";

import { IS_MOBILE } from "common/const";
import { useEffect, useRef, useState } from "react";

const InputArea = ({
  headerRef,
  setIsPremiumPopupOpen,
  emailRef,
  anonymousRef,
  emailTimesRef,
  isPremiumRef,
  textarea,
  setTextarea,
  placeholderText,
  setPlaceholderText,
  setIsFlipped,
  isVisibleRef,
  setIsVisible,
  setMessage,
  setIsLoginPopupOpen,
  setRephraseItem,
  anonymousTimesRef,
  setRevisionButtonClick,
}) => {
  const clickRephraseButtonRef = useRef(null);
  const clickRemoveButtonRef = useRef(null);
  const [clipText, setClipText] = useState("");
  const [rephraseStyle, setrephraseStyle] = useState("Rephrase");
  const [hasClipboardData, setHasClipboardData] = useState(false);

  const handleFocus = async () => {
    try {
      if (!IS_MOBILE) setClipText(await navigator.clipboard.readText());
    } catch (err) {
      console.error("Failed to read clipboard text: ", err);
    }
  };

  useEffect(() => {
    handleFocus();
    window.addEventListener("focus", handleFocus);

    let clipboardPlaceholderText;
    if (clipText === null || clipText === '') {
      clipboardPlaceholderText = '';
    } else {
      clipboardPlaceholderText = `Clipboard: \n${clipText}`;
    }

    IS_MOBILE
      ? setPlaceholderText(`Type something to ${rephraseStyle}.`)
      : setPlaceholderText(`Type or click on Paste & ${rephraseStyle} to paste from clipboard.\n\n${clipboardPlaceholderText}`);

    if (clipText !== "") setHasClipboardData(true);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipText, rephraseStyle]);

  const removeClicked = () => {
    setIsFlipped(false);
    setTextarea("");

    // On mobile clients, reading from clipboard throws error.
    if (IS_MOBILE) {
      return;
    }

    setClipText("");
  };

  const handleTextareaKeyDown = (event) => {
    if (event.key === "Escape" && !event.shiftKey) {
      event.preventDefault();
      clickRemoveButtonRef.current.click();
    }
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      clickRephraseButtonRef.current.click();
    }
  };

  return (
    <>
      <div className="input-area">
        <textarea
          // Hotjar hides text input by default unless enabled by additional
          // class "data-hj-allow".
          className="input-textarea data-hj-allow" 
          value={textarea}
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              setClipText("");
            }
            setTextarea(e.target.value);
          }}
          maxLength="8000"
          placeholder={placeholderText}
          onKeyDown={handleTextareaKeyDown}
        ></textarea>
        <p className="input-area-count-number">{textarea.length} / 8000</p>

        <button
          onClick={() => {
            removeClicked();
          }}
          className="remove-btn"
          data-testid="remove-btn"
          hidden={textarea === ""}
          ref={clickRemoveButtonRef}
        ></button>

        <RephraseButton
          headerRef={headerRef}
          anonymousRef={anonymousRef}
          anonymousTimesRef={anonymousTimesRef}
          emailRef={emailRef}
          emailTimesRef={emailTimesRef}
          setIsPremiumPopupOpen={setIsPremiumPopupOpen}
          isPremiumRef={isPremiumRef}
          isVisibleRef={isVisibleRef}
          setIsVisible={setIsVisible}
          setrephraseStyle={setrephraseStyle}
          hasClipboardData={hasClipboardData}
          setMessage={setMessage}
          clipText={clipText}
          clickRephraseButtonRef={clickRephraseButtonRef}
          rephraseStyle={rephraseStyle}
          setIsLoginPopupOpen={setIsLoginPopupOpen}
          setRephraseItem={setRephraseItem}
          textarea={textarea}
          setTextarea={setTextarea}
          setIsFlipped={setIsFlipped}
          setRevisionButtonClick={setRevisionButtonClick}
        />
      </div>
    </>
  );
};

export default InputArea;
