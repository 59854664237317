import "./LoginPopup.css";

import google from "assets/google.svg";

import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../utilities/firebaseUtils";

function LoginPopup({ isLoginPopupOpen, setLogin }) {
  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      setLogin(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      {isLoginPopupOpen && (
        <>
          <div className="overlay"></div>
          <div className="login-popup ">
            <div className="login-popup-inner">
              <h2>Please Sign In</h2>
              <p className="login-prompt-content">Please sign in with your Google account to continue using Phrasely!</p>
              <div className="google-icon-button-container">
                <button className="google-login-button" type="submit" onClick={signInWithGoogle}>
                  <img className="google-icon" src={google} alt="Icon" />
                  Continue with Google
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default LoginPopup;
