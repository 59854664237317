import { CATEGORY_ITEMS, IS_MOBILE } from "common/const";
import { useContext, useState } from "react";
import OpenaiUtils from "utilities/openaiUtils";
import { v4 } from "uuid";
import LanguageContext from "../contexts/LangContext";
import { analyticsEvent, handleTimesAction } from "../utilities/firebaseUtils";
import "./RephraseButton.css";
var DetectLanguage = require("detectlanguage");
const RephraseButton = ({
  headerRef,
  emailRef,
  anonymousRef,
  emailTimesRef,
  setIsPremiumPopupOpen,
  isPremiumRef,
  setrephraseStyle,
  hasClipboardData,
  clickRephraseButtonRef,
  textarea,
  clipText,
  setTextarea,
  setIsFlipped,
  rephraseStyle,
  setIsLoginPopupOpen,
  setMessage,
  isVisibleRef,
  setIsVisible,
  setRephraseItem,
  anonymousTimesRef,
  setRevisionButtonClick,
}) => {
  const [previousInput, setPreviousInput] = useState("");
  const [pasteText, setPasteText] = useState("Paste & Rephrase");
  const { setLang } = useContext(LanguageContext);
  const handleClick = (event) => {
    const styleOptions = {
      Casual: "Make Casual",
      Formal: "Make Formal",
    };

    const selectedItem = event.currentTarget.textContent;
    const selectedOption = styleOptions[selectedItem] || selectedItem;

    setrephraseStyle(selectedOption);
    setPasteText(`Paste & ${selectedOption}`);
    rephraseClicked(selectedOption);
  };

  const rephraseClicked = async (style) => {
    // Previously, when the rephrase button is clicked, the new revisions take
    // a second to be fully generated, the container holding the revisions would
    // have no changes during this time. It would seem as if the button was not
    // clicked, prompting the user to click a few more times. To fix this, we
    // want the container to scroll to the top, so they can see that the new
    // revisions are actually loading and being streamed.
    document.getElementsByClassName('output-area')[0].scrollTo(0,0);

    const checkTextAreaIsDiff = () => {
      if (previousInput !== textarea) {
        setRephraseItem([]);
      }
    };

    const rephraseTextArea = async () => {
      const promises = [];
      let detectlanguage = new DetectLanguage("40c26241c0bcc1980d161edc2db7a76e");
      detectlanguage.detectCode(textarea === "" ? clipText : textarea).then(function (result) {
        setLang(result);
        for (let i = 0; i < 3; ++i) {
          promises.push(
            (async () => {
              const uuid = v4();
              setRephraseItem((prev) => [
                {
                  id: uuid,
                  rephraseText: (
                    <OpenaiUtils
                      headers={headerRef.current}
                      anonymous={anonymousRef.current}
                      email={emailRef.current}
                      rephraseInput={textarea === "" ? clipText : textarea}
                      rephraseStyle={style}
                      id={uuid}
                      lang={result}
                    />
                  ),
                },
                ...prev,
              ]);
            })()
          );
        }
      });

      try {
        await Promise.all(promises);
      } catch (error) {
        console.error(error);
      }
    };
    // checkingTextareaHasDataOrNot
    if (textarea === "") {
      if (!clipText) return;
      if (clipText.length > 8000) {
        setMessage("Your texts are already exceeds 8000 words");
        setIsVisible(true);
        clearTimeout(isVisibleRef.current);
        isVisibleRef.current = setTimeout(() => {
          setIsVisible(false);
        }, 3000);
        analyticsEvent({
          whichCategory: "whichTypeTextFrom",
          whichTypeOf: "over 8000 words",
        });

        return;
      }
      analyticsEvent({
        whichCategory: "whichTypeTextFrom",
        whichTypeOf: "paste",
      });

      setTextarea(IS_MOBILE ? "" : clipText);
    } else {
      // console.log(previousInput !== textarea);
      previousInput !== textarea
        ? analyticsEvent({
            whichCategory: "whichTypeTextFrom",
            whichTypeOf: "type",
          })
        : analyticsEvent({
            whichCategory: "whichTypeTextFrom",
            whichTypeOf: "second times",
          });
    }

    await Promise.all([
      new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (anonymousTimesRef.current !== null && emailTimesRef.current !== null && isPremiumRef.current !== "placeholder" && headerRef.current !== null) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      }),
      new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (emailRef.current !== "Anonymous" && emailTimesRef.current !== "NotLogin" && isPremiumRef.current !== "NotLogin") {
            clearInterval(intervalId);
            resolve();
          } else if (emailRef.current === "Anonymous" && anonymousTimesRef.current !== "NotLogout") {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      }),
    ]);

    if (emailRef.current !== "Anonymous") {
      // console.log(emailRef.current);
      if (!isPremiumRef.current && emailTimesRef.current >= 100) {
        setIsPremiumPopupOpen(true);
        return;
      }
      emailTimesRef.current += 1;
      handleTimesAction("email", "addTimes", emailRef.current);
    } else {
      // console.log(emailRef.current);
      if (anonymousTimesRef.current >= 2) {
        setIsLoginPopupOpen(true);
        return;
      }
      anonymousTimesRef.current += 1;
      handleTimesAction("anonymous", "addTimes", anonymousRef.current);
    }
    setRevisionButtonClick(false);
    setIsFlipped(false);

    checkTextAreaIsDiff();
    setPreviousInput(textarea === "" ? clipText : textarea);
    await rephraseTextArea();
  };

  return (
    <div className="rephrase-container">
      <div className="btn-group dropup">
        <button className="btn btn-secondary rephrase rephrase-button-length" ref={clickRephraseButtonRef} onClick={() => rephraseClicked(rephraseStyle)}>
          {textarea !== "" || !hasClipboardData ? rephraseStyle : pasteText}
        </button>
        <button data-testid="rephrase-triangle" className="btn btn-secondary dropdown-toggle dropdown-toggle-split rephrase" data-bs-toggle="dropdown"></button>
        <ul className="dropdown-menu dropdown-menu-end">
          {CATEGORY_ITEMS.map((item, index) => (
            <li className="dropdown-item" onClick={handleClick} key={index}>
              <span className="btn-name">{item.name}</span>
              <img className="drop-up-icon" src={item.img} alt=" Icon" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default RephraseButton;
