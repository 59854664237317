import copyIcon from "assets/copy.png";
import { createRef, useRef } from "react";
import { performRevisionAction } from "../utilities/firebaseUtils";

const RephraseItem = ({ setMessage, isVisibleRef, setIsVisible, setRevisionText, setIsFlipped, idRef, isFlipped, id, rephraseText }) => {
  const rephraseRefs = useRef([]);

  const handleCopyClick = async (event) => {
    event.stopPropagation();
    try {
      //TODO need to change to react form
      await navigator.clipboard.writeText(document.getElementById(["outputDiv" + id]).textContent);
      setMessage("Text copied to clipboard");
      setIsVisible(true);
      clearTimeout(isVisibleRef.current);
      isVisibleRef.current = setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    } catch (err) {
      setMessage("Failed to copy text to clipboard");
      setIsVisible(true);
      clearTimeout(isVisibleRef.current);
      isVisibleRef.current = setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
    performRevisionAction(id, "markRevisionCopied");
  };

  const revisionTextClick = () => {
    setRevisionText(rephraseRefs.current[id].current.textContent);
    console.log(rephraseRefs.current[id].current.textContent);
    performRevisionAction(id, "markRevisionClicked");
    setIsFlipped((prevIsFlipped) => !prevIsFlipped);
    idRef.current = id;
  };

  if (!rephraseRefs.current[id]) {
    rephraseRefs.current[id] = createRef();
  }

  // Temporarily disabling the card flipping feature for now. Currently, 
  // clicking on the text or the copy button both copies the text. The reason
  // is because users had trouble copy and pasting the text. Also, once they 
  // clicked into a revision by accident, they couldn't return to the previous
  // screen. The revision explanation tool is also not frequently used.
  return (
    <>
      <div className={`output-area-rephrase-item-container ${isFlipped ? "is-flipped" : ""}`} id={`outputDiv${id}`}>
        <div className="output-area-rephrase-item" onClick={handleCopyClick} ref={rephraseRefs.current[id]}>
          {rephraseText}
        </div>
        <img src={copyIcon} alt="copy" className="copy-icon" onClick={handleCopyClick} />
      </div>
    </>
  );
};

export default RephraseItem;
