import "./Editor.css";

import icon from "assets/icon.svg";
import { signInAnonymously } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { auth, handleTimesAction } from "../utilities/firebaseUtils";
import InformButton from "./InformButton";
import InputArea from "./InputArea";
import LoginPopup from "./LoginPopup";
import OutputArea from "./OutputArea";
import PremiumPopup from "./PremiumPopup";
import PromotionBanner from "./PromotionBanner";
import SnackBar from "./SnackBar";

const Editor = () => {
  const isVisibleRef = useRef(null);
  const emailTimesRef = useRef(null);
  const headerRef = useRef(null);
  const anonymousTimesRef = useRef(null);
  const anonymousRef = useRef("");
  const emailRef = useRef("Anonymous");
  const isPremiumRef = useRef("placeholder");

  const [textarea, setTextarea] = useState("");

  const [message, setMessage] = useState("");
  const [placeholderText, setPlaceholderText] = useState("");
  const [login, setLogin] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);

  const [isPremiumPopupOpen, setIsPremiumPopupOpen] = useState(false);
  const [revisionButtonClick, setRevisionButtonClick] = useState(false);
  const [isLoadingForLoginButton, setIsLoadingForLoginButton] = useState(true);
  const [rephraseItem, setRephraseItem] = useState([]);

  useEffect(() => {
    //live message
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "//js-na1.hs-scripts.com/39576677.js";
    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  useEffect(() => {
    //login judge
    auth.onAuthStateChanged((user) => {
      user && !user.isAnonymous ? handleAuthenticatedUser() : handleAnonymousUser();
      setIsLoadingForLoginButton(false);
    });

    //token refresh
    auth.onIdTokenChanged(async (user) => {
      try {
        const userCredential = user ? null : await signInAnonymously(auth);
        const idToken = user ? await user.getIdToken() : userCredential.user.getIdToken();
        headerRef.current = { Authorization: "Bearer " + idToken };
      } catch (error) {
        console.log(error);
      }
    });
  }, []);

  const handleAuthenticatedUser = () => {
    anonymousTimesRef.current = "NotLogout";
    setIsLoginPopupOpen(false);
    setLogin(true);
    emailRef.current = auth.currentUser.email;

    // Allows Hotjar to associate user email with recording.
    window.hj('identify', auth.currentUser.uid, {
      email: auth.currentUser.email
    });

    auth.currentUser.getIdToken(true).then(async () => {
      emailTimesRef.current = await handleTimesAction("email", "createUserOrNot", auth.currentUser.email);
      const decodedToken = await auth.currentUser.getIdTokenResult();
      isPremiumRef.current = decodedToken.claims.stripeRole;
    });
  };

  const handleAnonymousUser = async () => {
    setLogin(false);
    emailRef.current = "Anonymous";
    emailTimesRef.current = "NotLogin";
    isPremiumRef.current = "NotLogin";
    const storedAnonymousId = localStorage.getItem("anonymousId");
    if (storedAnonymousId) {
      anonymousRef.current = storedAnonymousId;

      anonymousTimesRef.current = await handleTimesAction("anonymous", "createUserOrNot", storedAnonymousId);
    } else {
      const userCredential = await signInAnonymously(auth);
      const newAnonymousId = userCredential.user.uid;
      localStorage.setItem("anonymousId", newAnonymousId);
      anonymousRef.current = newAnonymousId;
      anonymousTimesRef.current = await handleTimesAction("anonymous", "createUserOrNot", newAnonymousId);
    }
  };

  return (
    <>
      {emailTimesRef.current >= 90 && isPremiumRef.current !== "premium" && <PromotionBanner times={emailTimesRef.current} />}

      <div className="navigator-bar-div">
        <div className="phrasely-icon-div">
          <img className="phrasely-icon" src={icon} alt="Phrasely Icon" />
        </div>
        <InformButton login={login} setIsPremiumPopupOpen={setIsPremiumPopupOpen} setLogin={setLogin} isLoadingForLoginButton={isLoadingForLoginButton} />
      </div>
      <div className="centered-content">
        <InputArea
          headerRef={headerRef}
          emailRef={emailRef}
          anonymousTimesRef={anonymousTimesRef}
          anonymousRef={anonymousRef}
          emailTimesRef={emailTimesRef}
          setIsPremiumPopupOpen={setIsPremiumPopupOpen}
          isPremiumRef={isPremiumRef}
          textarea={textarea}
          setTextarea={setTextarea}
          placeholderText={placeholderText}
          setPlaceholderText={setPlaceholderText}
          setIsVisible={setIsVisible}
          isVisibleRef={isVisibleRef}
          setMessage={setMessage}
          setIsFlipped={setIsFlipped}
          setIsLoginPopupOpen={setIsLoginPopupOpen}
          setRephraseItem={setRephraseItem}
          setRevisionButtonClick={setRevisionButtonClick}
        />
        <OutputArea
          headerRef={headerRef}
          isFlipped={isFlipped}
          anonymousRef={anonymousRef}
          emailRef={emailRef}
          textarea={textarea}
          setIsFlipped={setIsFlipped}
          rephraseItem={rephraseItem}
          setMessage={setMessage}
          setIsVisible={setIsVisible}
          isVisibleRef={isVisibleRef}
          revisionButtonClick={revisionButtonClick}
          setRevisionButtonClick={setRevisionButtonClick}
        />
      </div>
      <SnackBar message={message} isVisibleRef={isVisibleRef} setIsVisible={setIsVisible} isVisible={isVisible} />
      <LoginPopup isLoginPopupOpen={isLoginPopupOpen} setLogin={setLogin} />
      <PremiumPopup isPremiumPopupOpen={isPremiumPopupOpen} />
      {/* <Footer /> */}
    </>
  );
};

export default Editor;
