import { notPremiumURL, auth } from "./firebaseUtils";
import { getFunctions, httpsCallable } from "firebase/functions";

const getCustomClaimRole = async () => {
  await auth.currentUser.getIdToken(true);
  const decodedToken = await auth.currentUser.getIdTokenResult();
  return decodedToken.claims.stripeRole;
};

export const billingPortal = async () => {
  const newWindow = window.open("", "_blank");
  newWindow.document.write("<p>Loading...</p>");
  const customClaimRole = await getCustomClaimRole();
  // console.log(customClaimRole);
  if (customClaimRole === "premium") {
    const { data } = await httpsCallable(
      getFunctions(),
      "ext-firestore-stripe-payments-createPortalLink"
    )({ returnUrl: window.location.origin });
    console.log(data.url);
    newWindow.location.assign(data.url);
  } else {
    notPremiumURL(newWindow);
  }
};
