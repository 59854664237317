import "./PremiumPopup.css";
import { billingPortal } from "utilities/billingPortal";

const PremiumPopup = ({ isPremiumPopupOpen }) => {
  return (
    <div>
      {isPremiumPopupOpen && (
        <>
          <div className="overlay"></div>
          <div className="login-popup ">
            <div className="login-popup-inner">
              <h2>Please Upgrade Account</h2>
              <p className="upgrade-prompt-content">
                Reached the quota for free tier usage. Please support Phrasely
                by upgrading to a premium account. Thank you!
              </p>
              <button
                className="upgrade-account"
                type="submit"
                onClick={() => billingPortal()}
              >
                Upgrade Account
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PremiumPopup;
