import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { logEvent, getAnalytics } from "firebase/analytics";

// AAAA:add userProperties
// setUserProperties(analytics, { favorite_food: "apples" });

import {
  getFirestore,
  collection,
  setDoc,
  getDoc,
  updateDoc,
  doc,
  increment,
  query,
  where,
  orderBy,
  getDocs,
  onSnapshot,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

const analyticsEvent = async ({
  whichCategory,
  whichTypeOf,
  rephraseStyle,
  rephreasText,
  revision,
  rephraseOutput,
}) => {
  // console.log(rephraseStyle);
  switch (whichCategory) {
    case "whichTypeTextFrom":
      logEvent(analytics, whichCategory, {
        content_type: whichTypeOf,
      });
      break;
    case "rephraseItem":
      logEvent(analytics, whichCategory, {
        rephraseStyle: rephraseStyle,
        rephraseText: rephreasText,
        rephraseOutput: rephraseOutput,
      });
      break;
    case "revision":
      logEvent(analytics, whichCategory, {
        rephraseStyle: rephraseStyle,
        rephreasText: rephreasText,
        revision: revision,
      });
      break;

    default:
      break;
  }
};

const notPremiumURL = async (newWindow) => {
  const docRef = await addDoc(
    collection(db, "customers", auth.currentUser.uid, "checkout_sessions"),
    {
      price: "price_1Mx49lHoYFvXtjKV9tGDFQNJ",
      allow_promotion_codes: true,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    }
  );
  onSnapshot(docRef, (snap) => {
    const { error, url } = snap.data();
    if (error) alert(`An error occured: ${error.message}`);
    console.log(url);
    if (url) newWindow.location.assign(url);
  });
};

const handleTimesAction = async (type, action, id) => {
  const userDoc =
    type === "email"
      ? doc(db, "authorizedUseCount", id)
      : doc(db, "anonymousUseCount", id);
  switch (action) {
    case "addTimes":
      await updateDoc(userDoc, { times: increment(1) });
      break;

    case "createUserOrNot":
      const userDocSnapshot = await getDoc(userDoc);
      if (userDocSnapshot.exists()) {
        return userDocSnapshot.data().times;
      } else {
        await setDoc(userDoc, { times: 0 });
        return 0;
      }

    default:
      console.log("Invalid action");
      break;
  }
};

const updateDataRecord = async (
  email,
  input,
  style,
  id,
  suggestion,
  fingerprintId
) => {
  try {
    const docRef = doc(db, "revision", id);
    const data = {
      email,
      suggestion,
      timestamp: new Date(),
      input,
      fingerprintId,
      style,
    };
    await setDoc(docRef, data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const performRevisionAction = async (revisionIndex, action) => {
  const rephraseItem = collection(db, "revision");
  const docRef = doc(rephraseItem, revisionIndex);

  let updateData = {};

  switch (action) {
    case "markRevisionCopied":
      updateData = { copied: true };
      break;
    case "markRevisionClicked":
      updateData = { clicked: true };
      break;
    case "markRevisionExplanationClicked":
      updateData = { explained: true };
      break;
    default:
      throw new Error(`Invalid action: ${action}`);
  }

  await setDoc(docRef, updateData, { merge: true });

  logEvent(analytics, "updateData", {
    updateData: updateData,
  });
};

const getRevisionHistory = async (email) => {
  const q = query(
    collection(db, "revision"),
    where("email", "==", email),
    orderBy("timestamp", "desc")
  );
  const dataDocSnapshot = await getDocs(q);
  const groups = new Map();
  dataDocSnapshot.forEach((record) => {
    const key = `${record.data().input}-${record.data().style}`;
    const group = groups.get(key) || [];
    group.push(record);
    groups.set(key, group);
  });
  // For each group, find the suggestion where `clicked` is true, or the first suggestion if none are clicked.
  const suggestions = [];
  console.log(typeof suggestions);
  groups.forEach((group) => {
    let suggestion = null;
    group.some((record) => {
      if (record.data().clicked) {
        suggestion = record.data().suggestion;
        return true;
      }
      if (!suggestion) {
        suggestion = record.data().suggestion;
      }
    });
    if (suggestion) {
      suggestions.push({
        input: group[0].data().input,
        style: group[0].data().style,
        suggestion,
      });
    }
  });
  return suggestions;
};

export {
  googleProvider,
  auth,
  notPremiumURL,
  handleTimesAction,
  performRevisionAction,
  analyticsEvent,
  updateDataRecord,
  getRevisionHistory,
};
